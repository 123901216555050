<template>
     <div class="service body">
          <div class="container">
               <div class="columns">
                    <div class="column is-half">
                         <div class="vertical-align">
                              <div class="section-header-2">
                                   Cloud Computing Services
                              </div>
                              <p>
                                   CTL comes in as your committed partner for cloud computing services when your business demands are growing and your
                                   IT capacity for innovation is not able to keep up. With numerous applications clogging up the infrastructure, it
                                   can be hard to fill the gap and keep your IT capacity in sync with your business growth.
                              </p>
                         </div>
                    </div>
                    <div class="column is-half"><img class="sideimg" src="@/assets/images/services/cc.png" alt="" /></div>
               </div>

               <div class="columns details">
                    <div class="column is-half ">
                         <div class="line-top">
                              <div class="section-title">
                                   Benefits of ITeS System
                              </div>
                              <div>
                                   <p>
                                        Our technology consultants help with migration of your applications, infrastructure and business processes to
                                        the cloud. You can then eliminate your on-premises storage, servers and networks and switch to an
                                        enterprise-ready cloud. Our cloud consultancy service ensures security, scalability and data replication with
                                        zero downtime and regulatory compliance. Our cloud computing designs can help you to reduce operational
                                        expenses, boost innovation, unlock new possibilities, and realise your strategic IT objectives faster, or it
                                        could just be a tool to regain your lost core business focus. No matter what your goals are, capitalising on
                                        cloud computing is a significant IT decision—one that can have far-fetching implications on your general IT
                                        strategy, future IT technology acquisitions, regulatory obligations, staffing, governance, and eventual
                                        technology-enabled business goals realisation. Our cloud consulting offerings are designed to support you
                                        through your decision making process, from exploratory evaluations to cloud strategy and technology decisions.
                                   </p>
                              </div>
                         </div>
                    </div>
                    <div class="column is-half ">
                         <div class="line-top">
                              <div class="section-title">
                                   Specific Solutions
                              </div>
                              <p>
                                   Cloud Assignment <br />
                                   Optimised Cloud Performance<br />
                                   Cloud Design Deployment<br />
                                   Cloud Monitoring<br />
                                   Cloud Management<br />
                                   Cloud Disaster<br />
                              </p>

                              <div class="para-large mt-60">
                                   View Projects that have availed CTLs Solution in this category.
                                   <br />
                                   <router-link :to="{name:'PROJECTS'}" class="knowmore">Know More</router-link>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</template>

<script>
     export default {};
</script>

<style lang="scss" scoped>
   
</style>
